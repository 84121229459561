.details-header
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 22px
    margin-bottom: 24px
    button
        font-size: 22px
        border: none
        padding: 0
        background: none
        outline: none
        line-height: 1
        display: flex
        align-items: center
        font-family: "Inter", sans-serif
        transition: .2s
        cursor: pointer
        img 
            display: inline-block
            margin-right: 12px
            width: 45px
            vertical-align: middle
        &:hover
            transition: .2s
            opacity: .9
    p
        font-weight: 500

.trees-details
    table
        width: 100%
        border-collapse: collapse
        td, th
            border: 1px solid #83B5D4
            padding: 10px 24px
            text-align: center

@media only screen and (max-width : 768px)
    .details-header 
        font-size: 20px
        button
            font-size: 20px