.App-header
  .btn
    padding: 20px 40px

.btns-wrap .connect-wrap
  position: relative
  .connect-methods
    position: absolute
    top: calc(100% - 10px)
    right: 0
    width: 100%
    background: #fff
    padding: 20px 10px 10px
    border-radius: 0 0 13px 13px
    display: none
    + .btn
      position: relative
      z-index: 1
    &.active
      display: block
      + .btn
        border-radius: 13px 13px 0 0
        box-shadow: none
    .btn
      width: 100%
      padding: 16px 16px
      border-color: #968F8F
      background: #fff
      color: #000
      box-shadow: none
      text-align: left
      display: flex
      justify-content: space-between
      align-items: center
      &:not(:last-child)
        margin-bottom: 10px
      img
        display: inline-block
        vertical-align: middle
        width: 30px
        padding-left: 6px
  