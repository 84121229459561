.step-purchase
  .radio-wrap
    margin-bottom: 32px
    > div
        display: inline-block
        &:not(:last-child)
        label
            margin-right: 28px
    input
      width: auto
      margin-right: 8px
    