.pagination 
    display: flex
    .page-link 
        position: relative
        display: block
        color: #83B5D4
        background-color: #fff
        border: 1px solid #dee2e6
        transition: all .15s ease-in-out
        padding: 4px 8px
        margin-top: 24px
        &:hover 
            z-index: 2
            color: #83B5D4
            background-color: #e9ecef
            border-color: #dee2e6
            cursor: pointer
    .page-item 
        &:not(:first-child) .page-link
            margin-left: -1px    
        &.active .page-link 
            z-index: 3
            color: #fff
            background-color: #83B5D4
            border-color: #83B5D4
        
        &.disabled .page-link
            color: #dee2e6
            pointer-events: none
            background-color: #fff
            border-color: #dee2e6
    
.table-wrap
    overflow-x: auto
