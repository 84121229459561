.loader 
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0,0,0,.6)
  display: flex 
  align-items: center 
  justify-content: center
  z-index: 12
  svg path 
    fill: #D1C558
  &.block-hidden
    display: none