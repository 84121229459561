.copy-block
  padding-bottom: 32px
  border-bottom: 1px solid #83B5D4
  margin-bottom: 32px
  p
    display: inline-block
    position: relative
    .accent-color
      margin-right: 12px
  .copy-icon
    display: inline-block
    vertical-align: bottom
    img
      display: block
      width: 20px
  .tooltip
    position: absolute
    top: calc(100% + 4px)
    right: 0
    color: rgba(137, 235, 113, 1)
    transition: .2s
    font-size: 16px
  input
    opacity: 0
    width: 1px
    height: 1px
    border: none
    outline: none

.list-wrap
  border-bottom: 1px solid #83B5D4
  padding-bottom: 16px
  margin-bottom: 32px
.list
  display: flex
  width: 100%
  justify-content: space-between
  .list-col 
    max-width: 33%
    > div
      margin-bottom: 16px
      display: flex
      justify-content: space-between
      span:not(.accent-color)
          margin-right: 16px
      &.block-hidden
        display: none
      a
        color: #0071C4
        text-decoration: underline
    .mb-42
      margin-bottom: 42px
    .mb-68
      margin-bottom: 68px
    .mb-94
      margin-bottom: 94px

.section h2
  color: #A59B42
  background: linear-gradient(180deg, rgba(255, 230, 6, 0.87) 0%, #A59B42 52.4%, #EFD80B 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
  text-fill-color: transparent
  font-size: 26px
  margin-bottom: 16px
  font-weight: 600

.step-purchase
  .field-wrap
    > *:not(last-child)
      margin-right: 16px
  .input-wrap
    position: relative
    display: inline-block
    span
      position: absolute
      bottom: 100%
      font-size: 12px
      &.min-value
        left: 0
      &.max-value
        right: 0

  .btn-withdraw
    margin-left: auto
    display: block
    width: fit-content
    &.block-hidden
      display: none

@media only screen and (max-width : 768px)
  .list-wrap
    padding-bottom: 0
    border: none
    margin: 0
  .list
    flex-direction: column
    .list-col
      max-width: 100%
      border-bottom: 1px solid #83B5D4
      margin-bottom: 20px
      > div
        margin: 0 0 16px !important
      &:first-child
        > div:nth-child(3)
          margin: 0 !important
      &:last-child
        > div:first-child
          margin: 0 !important
  .step-purchase .field-wrap 
    margin-bottom: 8px
    > *:not(last-child)
      margin-bottom:8px
    > label
      display: block 
      margin-bottom: 16px
  .copy-block
    margin-bottom: 20px
    padding-bottom: 20px
        