.step-mint
  text-align: center
  max-width: 600px
  margin: 0 auto
  p
    margin-bottom: 24px
  a
    color: #D1C558
  p, label
    // margin-right: 24px
    // display: inline-block
    // vertical-align: middle