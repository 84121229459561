.intro
    color: #fff
    flex-grow: 2
    display: flex 
    flex-direction: column
    a
        color: #D1C558
    p, li
        margin-bottom: 24px
    .btn
        background: linear-gradient(180deg, rgb(149, 243, 125) 3.65%, rgb(137, 235, 113) 51.56%, rgb(149, 243, 125) 100%)
        border: 1px solid #fff
        color: #fff
        margin: 0 auto
        display: none
        width: fit-content
        &.active
            display: block
    .btn-less
        margin-top: 16px
.intro-min-content
    flex-grow: 2
.intro-max
    display: none
    margin-top: 72px
    &.active
        display: flex
    li
        margin-bottom: 4px
    ul
        margin-bottom: 40px