@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap')


*
  margin: 0
  padding: 0
  box-sizing: border-box
 
.clearfix::after
  content: ''
  display: table
  clear: both
  
.content
  max-width: 1280px
  padding: 32px
  margin: 0px auto
  display: block
 
a:hover, a
  text-decoration: inherit
  color: inherit
  
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active
  transition: background-color 5000s ease-in-out 0s
  -webkit-text-fill-color: #000 !important
  color: #000 !important
 
ul
  list-style-type: none
 
form
  ::-webkit-input-placeholder
    font: 18px/1 'Inter'
    color: #000
  ::-moz-placeholder
    font: 18px/1 'Inter'
    color: #000
  :-moz-placeholder
    font: 18px/1 'Inter'       
    color: #000
  :-ms-input-placeholder
    font: 18px/1 'Inter'    
    color: #000
   
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0
 


