.App
  margin: 0 auto
  font-family: 'Inter', sans-serif
  color: #000
  font-size: 18px
  line-height: 1.2
  background: url(../public/bg.png) no-repeat right bottom
  background-size: 50%
  min-height: 100vh
  display: flex
  flex-direction: column
  &.not-connected
    background: url(../public/notConnected.jpg) no-repeat center
    background-size: cover
    background-attachment: fixed
    position: relative
    &::after
      content: ''
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      background: rgba(0,0,0,.5)
    .App-header
      background: rgba(0,0,0,.4)
      position: relative
      .content::after 
        display: none
      .btn
        background: linear-gradient(180deg, rgba(149, 243, 125, 1) 3.65%, rgba(137, 235, 113, 1) 51.56%, rgba(149, 243, 125, 1) 100%)
        border: 1px solid #fff
        color: #fff
    .App-content
      flex-grow: 2
      display: flex 
      > .content
        flex-grow: 2
        display: flex 
        flex-direction: column
     

.btn
  display: inline-block
  border: none
  outline: none
  font-weight: 400
  font-size: 18px
  line-height: 1
  padding: 12px 16px
  transition: .2s
  color: #fff
  text-align: center
  cursor: pointer
  font-family: 'Inter'
  background: linear-gradient(180deg, #D1C558 4.69%, #A59B42 51.65%, #D1C558 100%)
  border: 1px solid #959595
  border-radius: 13px
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)
  min-width: 120px
  &:hover, &:active
    box-shadow: none
    transition: .2s
  &.btn-theme
    background: linear-gradient(180deg, rgba(149, 243, 125, 0.2) 3.65%, rgba(137, 235, 113, 0.89) 51.56%, rgba(149, 243, 125, 0.2) 100%)
    border: 1px solid #968F8F
    color: #000
  &.btn-light
    background: linear-gradient(180deg, rgba(174, 214, 236, 0.6) 3.65%, rgba(105, 178, 219, 0.89) 51.56%, rgba(174, 214, 236, 0.6) 100%)
    border: 1px solid #968F8F
    color: #000
  &.btn-disabled
    background: linear-gradient(180deg, rgba(154, 154, 154, 0.2) 3.65%, rgba(154, 154, 154, 0.89) 51.56%, rgba(154, 154, 154, 0.2) 100%)
    border: 1px solid #968F8F
    pointer-events: none
    color: #000

.field-wrap
  margin-bottom: 24px
  input
    background: #F8F8F8
    border: 1px solid #125D8C
    outline: none
    border-radius: 13px
    width: 120px
    padding: 13px 16px
    text-align: center
    line-height: 1
    font-family: 'Inter'

.accent-color 
  color: #0071C4

.App-header 
  position: relative
  z-index: 10
  .content
    display: flex
    justify-content: space-between
    align-items: center
    &::after
      content: ''
      position: absolute
      top: 100%
      left: 32px
      right:32px 
      width: calc(100% - 64px)
      height: 1px
      background: #83B5D4
    .logo
      img
        display: block
        width: 320px

.App-content
  position: relative
  z-index: 1

.lang-switcher
  flex-grow: 2
  text-align: right
  margin-right: 16px
  button
    background: none
    border: none
    outline: none
    color: #D1C558
    text-decoration: underline
    cursor: pointer
    text-transform: uppercase
    margin: 0 8px
    &.active
      text-decoration: none
      cursor: none
      pointer-events: none

.block-hidden
    display: none

.notification-container
  top: initial
  bottom: 16px

.notification-error
  border-radius: 13px
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%)
  font-size: 16px

@media only screen and (max-width : 992px)
  .content
    padding: 24px 20px
  .App-header .content .logo img
    max-width: 200px
    width: 100%


@media only screen and (max-width : 768px)
  .App
    background: none
    font-size: 16px
  .App-header .content 
    flex-wrap: wrap
    &::after
      left: 20px
      right: 20px
      width: calc(100% - 40px)
    .logo img
      max-width: 160px
    .btns-wrap
      width: 100%
      text-align: right
      margin-top: 16px
      .btn
        padding: 16px 40px
        font-size: 16px
  .lang-switcher
    margin-top: 10px
    margin-right: 0

  .btns-wrap .connect-wrap 
      width: fit-content
      margin-left: auto
